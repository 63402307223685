<template>
  <div class="about-menu">
    <router-link to="/about">{{$t('about_menu.about')}}</router-link>
    <router-link to="/history">{{$t('about_menu.history')}}</router-link>
  </div>
</template>

<script>
export default {
  name: 'AboutMenu'
}
</script>
