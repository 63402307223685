<template>
  <section class="about-facts">
    <div class="container-about">
      <h3>{{$t('about_facts_title')}}</h3>
      <div class="about-facts__list">
        <div class="about-facts__item">
          <CupLineIcon />
          <h4>{{$t('about_facts_item_1.heading')}}</h4>
          <p>{{$t('about_facts_item_1.text')}}</p>
        </div>
        <div class="about-facts__item">
          <ShieldIcon />
          <h4>{{$t('about_facts_item_2.heading')}}</h4>
          <p>{{$t('about_facts_item_2.text')}}</p>
        </div>
        <div class="about-facts__item">
          <EasyIcon />
          <h4>{{$t('about_facts_item_3.heading')}}</h4>
          <p>{{$t('about_facts_item_3.text')}}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CupLineIcon from '@/components/about/assets/svg/CupLineIcon'
import ShieldIcon from '@/components/about/assets/svg/ShieldIcon'
import EasyIcon from '@/components/about/assets/svg/EasyIcon'

export default {
  name: 'AboutShortFacts',
  components: {
    CupLineIcon,
    ShieldIcon,
    EasyIcon
  }
}
</script>
