<template>
  <section class="about-awards">
    <div class="container-about">
      <h3>{{$t('about_awards')}}</h3>
    </div>
    <div class="about-awards__slider">
      <swiper ref="aboutAwardsSwiper" :options="swiperOptions" :autoplay="{ delay: 2500, disableOnInteraction: false }">
        <swiper-slide
          v-for="award in awards"
          :key="award.code"
        >
          <div class="about-awards__item">
            <AwardIcon/>
            <span v-if="award.winner"><CupIcon/> <i>{{$t('about_awards_winner')}}</i></span>
            <h4>{{ award.name }}</h4>
            <p>{{ award.desc }}</p>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
import AwardIcon from '@/components/about/assets/svg/AwardIcon'
import CupIcon from '@/components/about/assets/svg/CupIcon'

import awesomeSwiperBuilder from 'vue-awesome-swiper/dist/exporter'
import SwiperConstructor from 'swiper'
import { mapActions } from 'vuex'

const { Swiper, SwiperSlide } = awesomeSwiperBuilder(SwiperConstructor)

export default {
  name: 'AboutAwards',
  components: {
    CupIcon,
    AwardIcon,
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 64,
        loop: true,
        speed: 15000,
        autoplay: {
          enabled: true,
          delay: 1,
          disableOnInteraction: false
        },
        breakpoints: {
          320: {
            spaceBetween: 48
          },
          768: {
            spaceBetween: 64
          }
        }
      }
    }
  },
  mounted () {
    this.getAwards(1)
  },
  methods: {
    ...mapActions([
      'getAwards'
    ])
  },
  computed: {
    swiper () {
      return this.$refs.aboutAwardsSwiper.$swiper
    },
    awards () {
      return this.$store.state.awards
    }
  }
}
</script>

<style lang="scss">
@import "swiper/swiper.scss";

.swiper-wrapper {
  transition-timing-function: linear;
}
.swiper-slide{
  width: 280px;

  @media (max-width: 767px){
    width: 200px;
  }
}
</style>
