<template>
  <section class="about-offer">
    <div class="container-about">
      <div class="title-group">
        <h3>{{$t('about_offer_title')}}</h3>
        <p>{{$t('about_offer_desc')}}</p>
      </div>
      <div class="about-offer__list">
        <div class="about-offer__item comprehensive">
          <h4>{{$t('about_offer_item_1.heading')}}</h4>
          <p>{{$t('about_offer_item_1.text')}}</p>
        </div>
        <div class="about-offer__item tpl">
          <h4>{{$t('about_offer_item_2.heading')}}</h4>
          <p>{{$t('about_offer_item_2.text')}}</p>
        </div>
        <div class="about-offer__item outbound">
          <h4>{{$t('about_offer_item_3.heading')}}</h4>
          <p>{{$t('about_offer_item_3.text')}}</p>
        </div>
        <div class="about-offer__item inbound">
          <h4>{{$t('about_offer_item_4.heading')}}</h4>
          <p>{{$t('about_offer_item_4.text')}}</p>
        </div>
        <div class="about-offer__item home">
          <h4>{{$t('about_offer_item_5.heading')}}</h4>
          <p>{{$t('about_offer_item_5.text')}}</p>
        </div>
        <div class="about-offer__item pab">
          <h4>{{$t('about_offer_item_6.heading')}}</h4>
          <p>{{$t('about_offer_item_6.text')}}</p>
        </div>
        <div class="about-offer__item moto">
          <h4>{{$t('about_offer_item_7.heading')}}</h4>
          <p>{{$t('about_offer_item_7.text')}}</p>
        </div>
        <div class="about-offer__item boat">
          <h4>{{$t('about_offer_item_8.heading')}}</h4>
          <p>{{$t('about_offer_item_8.text')}}</p>
        </div>
        <div class="about-offer__item qlm">
          <h4>{{$t('about_offer_item_9.heading')}}</h4>
          <p>{{$t('about_offer_item_9.text')}}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutWhatOffer',
  mounted () {
    const slider = document.querySelector('.about-offer__list')
    let isDown = false
    let startX
    let scrollLeft
    slider.addEventListener('mousedown', (e) => {
      isDown = true
      slider.classList.add('active')
      startX = e.pageX
      scrollLeft = slider.scrollLeft
    })
    slider.addEventListener('mouseup', () => {
      isDown = false
      slider.classList.remove('active')
    })
    slider.addEventListener('mousemove', (e) => {
      if (!isDown) return
      const x = e.pageX
      const walk = (x - startX) * 1
      slider.scrollLeft = scrollLeft - walk
    })
  }
}
</script>
