<template>
  <section class="about-links">
    <div class="container-about">
      <div class="about-links__list">
        <div class="about-links__item about-links__item_white">
          <div class="about-links__item-content">
            <h4>{{$t('about_links_1.heading')}}</h4>
            <p>{{$t('about_links_1.text_1')}}</p>
          </div>
          <router-link to="/contacts" class="about-links__btn about-links__btn_white">{{$t('about_links_1.button')}} <arrow-icon /></router-link>
        </div>

        <div class="about-links__item">
          <div class="about-links__item-content">
            <h4>{{$t('about_links_2.heading')}}</h4>
            <p>{{$t('about_links_2.text_1')}}.</p>
          </div>
          <div class="about-links__item-icon"><HistoryIcon/></div>
          <router-link to="/history" class="about-links__btn">{{$t('about_links_2.button')}} <arrow-icon /></router-link>
        </div>

        <div class="about-links__item">
          <div class="about-links__item-content">
            <h4>{{$t('about_links_3.heading')}}</h4>
            <p>{{$t('about_links_3.text_1')}}</p>
            <p>{{$t('about_links_3.text_2')}}</p>
          </div>
          <a href="https://qic-group.com/" target="_blank" class="about-links__btn">{{$t('about_links_3.button')}} <arrow-icon /></a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ArrowIcon from '@/components/about/assets/svg/ArrowIcon'
import HistoryIcon from '@/components/about/assets/svg/HistoryIcon'
export default {
  name: 'AboutLinks',
  components: { HistoryIcon, ArrowIcon }
}
</script>
