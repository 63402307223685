<template>
  <section class="why-qic">
    <div class="container-about">
      <h3>{{$t('about_why_qic_title')}}</h3>
      <div class="why-qic__list">
        <div class="why-qic__item">
          <img src="@/components/about/assets/img/convenience.png" loading="lazy" alt="convenience qic">
          <h4>{{$t('about_why_qic_item_1.heading')}}</h4>
          <ul class="why-qic__item-list">
            <li>{{$t('about_why_qic_item_1.point_1')}}</li>
            <li>{{$t('about_why_qic_item_1.point_2')}}</li>
            <li>{{$t('about_why_qic_item_1.point_3')}}</li>
            <li>{{$t('about_why_qic_item_1.point_4')}}</li>
          </ul>
        </div>
        <div class="why-qic__item">
          <img src="@/components/about/assets/img/reputation.png" loading="lazy" alt="Reputation qic">
          <h4>{{$t('about_why_qic_item_2.heading')}}</h4>
          <ul class="why-qic__item-list">
            <li>{{$t('about_why_qic_item_2.point_1')}}</li>
            <li>{{$t('about_why_qic_item_2.point_2')}}</li>
            <li>{{$t('about_why_qic_item_2.point_3')}}</li>
            <li>{{$t('about_why_qic_item_2.point_4')}}</li>
          </ul>
        </div>
        <div class="why-qic__item">
          <img src="@/components/about/assets/img/results.png" loading="lazy" alt="Results qic">
          <h4>{{$t('about_why_qic_item_3.heading')}}</h4>
          <ul class="why-qic__item-list">
            <li>{{$t('about_why_qic_item_3.point_1')}}</li>
            <li>{{$t('about_why_qic_item_3.point_2')}}</li>
            <li>{{$t('about_why_qic_item_3.point_3')}}</li>
            <li>{{$t('about_why_qic_item_3.point_4')}}</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutWhyQic'
}
</script>
