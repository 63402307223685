<template>
  <section class="about-qic">
    <div class="container-about">
      <div class="about-qic__offer">
        <h1>{{$t('about_qic.title')}}</h1>
      </div>
      <div class="about-qic__desc">
        <p>{{$t('about_qic.text_1')}}</p>
        <div class="about-qic__desc-long">
          <div class="about-qic__desc-text" :class="{ 'about-qic__desc-text_visible': readMore }">
            <p>{{$t('about_qic.text_2')}}</p>
            <p>{{$t('about_qic.text_3')}}</p>
            <p>{{$t('about_qic.text_4')}}</p>
            <p>{{$t('about_qic.text_5')}}</p>
          </div>
          <div class="about-qic__desc-more" @click="toggleReadMore">{{ btnText }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutQic',
  data () {
    return {
      readMore: false
    }
  },
  methods: {
    toggleReadMore () {
      this.readMore = !this.readMore
    }
  },
  computed: {
    btnText: function () {
      if (this.readMore) {
        return this.$t('about_qic.read_less')
      }
      return this.$t('about_qic.read_more')
    }
  }
}
</script>
